<template>
	<div class="tcontainer">
		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<div class="pull-right" style="margin: 5px;">
				Total Centers: {{ rows?.length }}
			</div>
			<div class="title">Centers</div>
		</div>
		<table class="table table-bordered table-striped" style="min-width: 1500px;">
			<thead>
				<tr>
					<th>ID</th>
					<th>Center Name</th>
					<th>Contact Person</th>
					<th>Phone</th>
					<th>Single View</th>
					<th>Double View</th>
					<th>Multi View</th>
					<th>Both View</th>
					<th>Other View</th>
					<th>OPG</th>
					<th>ECG</th>
					<th>TMT</th>
					<th>PFT</th>
					<th>EEG2</th>
					<th>EEG3</th>
					<th>EEG4</th>
					<th>EEG5</th>
					<th>EEG6</th>
					<th>EEG7</th>
					<th v-if="false">Commission</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in rows" :key="row">
					<td>{{ row.id }}</td>
					<td>{{ row.name }}</td>
					<td>{{ row.contact_person }}</td>
					<td>{{ row.phone }}</td>
					<td>&#8377;{{ row.agent_price_single_view }} / &#8377;{{ row.price_single_view }}</td>
					<td>&#8377;{{ row.agent_price_double_view }} / &#8377;{{ row.price_double_view }}</td>
					<td>&#8377;{{ row.agent_price_multi_view }} / &#8377;{{ row.price_multi_view }}</td>
					<td>&#8377;{{ row.agent_price_both_view }} / &#8377;{{ row.price_both_view }}</td>
					<td>&#8377;{{ row.agent_price_other_view }} / &#8377;{{ row.price_other_view }}</td>
					<td>&#8377;{{ row.agent_price_opg }} / &#8377;{{ row.price_opg }}</td>
					<td>&#8377;{{ row.cardiology_agent_price }} / &#8377;{{ row.cardiology_price }}</td>
					<td>&#8377;{{ row.tmt_agent_price }} / &#8377;{{ row.tmt_price }}</td>
					<td>&#8377;{{ row.pulmonology_agent_price }} / &#8377;{{ row.pulmonology_price }}</td>
					<td>&#8377;{{ row.eeg2_agent_price }} / &#8377;{{ row.eeg2_price }}</td>
					<td>&#8377;{{ row.eeg3_agent_price }} / &#8377;{{ row.eeg3_price }}</td>
					<td>&#8377;{{ row.eeg4_agent_price }} / &#8377;{{ row.eeg4_price }}</td>
					<td>&#8377;{{ row.eeg5_agent_price }} / &#8377;{{ row.eeg5_price }}</td>
					<td>&#8377;{{ row.eeg6_agent_price }} / &#8377;{{ row.eeg6_price }}</td>
					<td>&#8377;{{ row.eeg7_agent_price }} / &#8377;{{ row.eeg7_price }}</td>
					<td v-if="false">
						<table class="table">
							<tbody>
								<tr>
									<td>Single View: </td>
									<td>&#8377;{{ row.agent_price_single_view }} / &#8377;{{ row.price_single_view }}</td>
								</tr>
								<tr>
									<td>Double View: </td>
									<td>&#8377;{{ row.agent_price_double_view }} / &#8377;{{ row.price_double_view }}</td>
								</tr>
								<tr>
									<td>Multi View: </td>
									<td>&#8377;{{ row.agent_price_multi_view }} / &#8377;{{ row.price_multi_view }}</td>
								</tr>
								<tr>
									<td>Both View: </td>
									<td>&#8377;{{ row.agent_price_both_view }} / &#8377;{{ row.price_both_view }}</td>
								</tr>
								<tr>
									<td>Other View: </td>
									<td>&#8377;{{ row.agent_price_other_view }} / &#8377;{{ row.price_other_view }}</td>
								</tr>
								<tr>
									<td>Cardiology: </td>
									<td>&#8377;{{ row.cardiology_agent_price }} / &#8377;{{ row.cardiology_price }}</td>
								</tr>
								<tr>
									<td>TMT: </td>
									<td>&#8377;{{ row.tmt_agent_price }} / &#8377;{{ row.tmt_price }}</td>
								</tr>
							</tbody>
						</table>

					</td>
				</tr>
			</tbody>
		</table>

		<form action="" @submit.prevent="putCenter">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								Add Center
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">

							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.name?.hasError && fcontrol.name.touched }"
												v-model="fdata.name" @keyup="makeCenterCode" @blur="fcontrol.name.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.name?.hasError">
												<span v-if="fcontrol.name.errors.required">You must enter name</span>
												<span v-if="fcontrol.name.errors.max">Name can contain maximum
													{{ fcontrol.name.errors.max.maxLength }} characters</span>
											</div>
											<label>Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.code?.hasError && fcontrol.code.touched }"
												v-model="fdata.code" @blur="fcontrol.code.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.code?.hasError">
												<span v-if="fcontrol.code.errors.required">You must enter code</span>
												<span v-if="fcontrol.code.errors.max">Code can contain maximum
													{{ fcontrol.code.errors.max.maxLength }} characters</span>
											</div>
											<label>Code</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.email?.hasError && fcontrol.email.touched }"
												v-model="fdata.email" @blur="fcontrol.email.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.email?.hasError">
												<span v-if="fcontrol.email.errors.required">You must enter email</span>
												<span v-if="fcontrol.email.errors.max">Email can contain maximum
													{{ fcontrol.email.errors.max.maxLength }} characters</span>
											</div>
											<label>Email</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.password?.hasError && fcontrol.password.touched }"
												v-model="fdata.password" @blur="fcontrol.password.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.password?.hasError">
												<span v-if="fcontrol.password.errors.required">You must enter password</span>
												<span v-if="fcontrol.password.errors.max">Password can contain maximum
													{{ fcontrol.password.errors.max.maxLength }} characters</span>
											</div>
											<label>Password</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.contact_person?.hasError && fcontrol.contact_person.touched }"
												v-model="fdata.contact_person" @blur="fcontrol.contact_person.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.contact_person?.hasError">
												<span v-if="fcontrol.contact_person.errors.required">You must enter contact
													person</span>
												<span v-if="fcontrol.contact_person.errors.max">Contact Person can contain maximum
													{{ fcontrol.contact_person.errors.max.maxLength }} characters</span>
											</div>
											<label>Contact Person</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.phone?.hasError && fcontrol.phone.touched }"
												v-model="fdata.phone" @blur="fcontrol.phone.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.phone?.hasError">
												<span v-if="fcontrol.phone.errors.required">You must enter phone</span>
												<span v-if="fcontrol.phone.errors.max">Phone can contain maximum
													{{ fcontrol.phone.errors.max.maxLength }} characters</span>
											</div>
											<label>Phone</label>
										</div>

										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.state?.hasError && fcontrol.state.touched }"
												v-model="fdata.state" @blur="fcontrol.state.touched = true">
												<option vlaue="Andhra Pradesh">Andhra Pradesh</option>
												<option vlaue="Arunachal Pradesh">Arunachal Pradesh</option>
												<option vlaue="Assam">Assam</option>
												<option vlaue="Bihar">Bihar</option>
												<option vlaue="Chhattisgarh">Chhattisgarh</option>
												<option vlaue="Goa">Goa</option>
												<option vlaue="Gujarat">Gujarat</option>
												<option vlaue="Haryana">Haryana</option>
												<option vlaue="Himachal Pradesh">Himachal Pradesh</option>
												<option vlaue="Jharkhand">Jharkhand</option>
												<option vlaue="Karnataka">Karnataka</option>
												<option vlaue="Kerala">Kerala</option>
												<option vlaue="Madhya Pradesh">Madhya Pradesh</option>
												<option vlaue="Maharashtra">Maharashtra</option>
												<option vlaue="Manipur">Manipur</option>
												<option vlaue="Meghalaya">Meghalaya</option>
												<option vlaue="Mizoram">Mizoram</option>
												<option vlaue="Nagaland">Nagaland</option>
												<option vlaue="Odisha">Odisha</option>
												<option vlaue="Punjab">Punjab</option>
												<option vlaue="Rajasthan">Rajasthan</option>
												<option vlaue="Sikkim">Sikkim</option>
												<option vlaue="Tamil Nadu">Tamil Nadu</option>
												<option vlaue="Telangana">Telangana</option>
												<option vlaue="Tripura">Tripura</option>
												<option vlaue="Uttar Pradesh">Uttar Pradesh</option>
												<option vlaue="Uttarakhand">Uttarakhand</option>
												<option vlaue="West Bengal">West Bengal</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.state?.hasError">
												<span v-if="fcontrol.state.errors.required">You must select state</span>
											</div>
											<label>State</label>
										</div>

										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.address?.hasError && fcontrol.address.touched }"
												v-model="fdata.address" @blur="fcontrol.address.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.address?.hasError">
												<span v-if="fcontrol.address.errors.required">You must enter address</span>
												<span v-if="fcontrol.address.errors.max">Address can contain maximum
													{{ fcontrol.address.errors.max.maxLength }} characters</span>
											</div>
											<label>Address</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.show_disclaimer?.hasError && fcontrol.show_disclaimer.touched }"
												v-model="fdata.show_disclaimer" @blur="fcontrol.show_disclaimer.touched = true">
												<option value="" disabled selected>Select Is Active</option>
												<option value="y">Yes</option>
												<option value="n">No</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.show_disclaimer?.hasError">
												<span v-if="fcontrol.show_disclaimer.errors.required">You must select show
													disclaimer</span>
												<span v-if="fcontrol.show_disclaimer.errors.in">Invalid show disclaimer</span>
											</div>
											<label>Show Disclaimer</label>
										</div>
										<div class="ecg-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_price?.hasError && fcontrol.cardiology_price.touched }"
													v-model="fdata.cardiology_price" @blur="fcontrol.cardiology_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('cardiology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.cardiology_price?.hasError">
													<span v-if="fcontrol.cardiology_price.errors.required">You must enter ECG test
														price</span>
												</div>
												<label>ECG Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_price?.hasError && fcontrol.tmt_price.touched }"
													v-model="fdata.tmt_price" @blur="fcontrol.tmt_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('tmt_price')" />
												<div class="invalid-feedback" v-if="fcontrol.tmt_price?.hasError">
													<span v-if="fcontrol.tmt_price.errors.required">You must enter TMT test
														price</span>
												</div>
												<label>TMT Test Price</label>
											</div>
										</div>
										<div class="pft-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_price?.hasError && fcontrol.pulmonology_price.touched }"
													v-model="fdata.pulmonology_price" @blur="fcontrol.pulmonology_price.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('pulmonology_price')" />
												<div class="invalid-feedback" v-if="fcontrol.pulmonology_price?.hasError">
													<span v-if="fcontrol.pulmonology_price.errors.required">You must enter PFT test price</span>
												</div>
												<label>PFT Test Price</label>
											</div>
										</div>
										<div class="eeg-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg2_price?.hasError && fcontrol.eeg2_price.touched }"
													v-model="fdata.eeg2_price" @blur="fcontrol.eeg2_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('eeg2_price')" />
												<div class="invalid-feedback" v-if="fcontrol.eeg2_price?.hasError">
													<span v-if="fcontrol.eeg2_price.errors.required">
														You must enter EEG 2 test price
													</span>
												</div>
												<label>EEG 2 Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg3_price?.hasError && fcontrol.eeg3_price.touched }"
													v-model="fdata.eeg3_price" @blur="fcontrol.eeg3_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('eeg3_price')" />
												<div class="invalid-feedback" v-if="fcontrol.eeg3_price?.hasError">
													<span v-if="fcontrol.eeg3_price.errors.required">
														You must enter EEG 3 test price
													</span>
												</div>
												<label>EEG 3 Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg4_price?.hasError && fcontrol.eeg4_price.touched }"
													v-model="fdata.eeg4_price" @blur="fcontrol.eeg4_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('eeg4_price')" />
												<div class="invalid-feedback" v-if="fcontrol.eeg4_price?.hasError">
													<span v-if="fcontrol.eeg4_price.errors.required">
														You must enter EEG 4 test price
													</span>
												</div>
												<label>EEG 4 Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg5_price?.hasError && fcontrol.eeg5_price.touched }"
													v-model="fdata.eeg5_price" @blur="fcontrol.eeg5_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('eeg5_price')" />
												<div class="invalid-feedback" v-if="fcontrol.eeg5_price?.hasError">
													<span v-if="fcontrol.eeg5_price.errors.required">
														You must enter EEG 5 test price
													</span>
												</div>
												<label>EEG 5 Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg6_price?.hasError && fcontrol.eeg6_price.touched }"
													v-model="fdata.eeg6_price" @blur="fcontrol.eeg6_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('eeg6_price')" />
												<div class="invalid-feedback" v-if="fcontrol.eeg6_price?.hasError">
													<span v-if="fcontrol.eeg6_price.errors.required">
														You must enter EEG 6 test price
													</span>
												</div>
												<label>EEG 6 Test Price</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg7_price?.hasError && fcontrol.eeg7_price.touched }"
													v-model="fdata.eeg7_price" @blur="fcontrol.eeg7_price.touched = true"
													placeholder=" " :readonly="!!(editId && !isSuper)" @keyup="calculateAgentPrice('eeg7_price')" />
												<div class="invalid-feedback" v-if="fcontrol.eeg7_price?.hasError">
													<span v-if="fcontrol.eeg7_price.errors.required">
														You must enter EEG 7 test price
													</span>
												</div>
												<label>EEG 7 Test Price</label>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="center-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_single_view?.hasError && fcontrol.price_single_view.touched }"
													v-model="fdata.price_single_view" @blur="fcontrol.price_single_view.touched = true"
													placeholder=" " @keyup="doubleRate('single_view'); calculateAgentPrice('price_single_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_single_view?.hasError">
													<span v-if="fcontrol.price_single_view.errors.required">You must enter price
														single view</span>
												</div>
												<label>Price Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_double_view?.hasError && fcontrol.price_double_view.touched }"
													v-model="fdata.price_double_view" @blur="fcontrol.price_double_view.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_double_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_double_view?.hasError">
													<span v-if="fcontrol.price_double_view.errors.required">You must enter price
														double view</span>
												</div>
												<label>Price Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_multi_view?.hasError && fcontrol.price_multi_view.touched }"
													v-model="fdata.price_multi_view" @blur="fcontrol.price_multi_view.touched = true"
													placeholder=" " @keyup="doubleRate('multi_view'); calculateAgentPrice('price_multi_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_multi_view?.hasError">
													<span v-if="fcontrol.price_multi_view.errors.required">You must enter price multi
														view</span>
												</div>
												<label>Price Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_both_view?.hasError && fcontrol.price_both_view.touched }"
													v-model="fdata.price_both_view" @blur="fcontrol.price_both_view.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_both_view')" readonly />
												<div class="invalid-feedback" v-if="fcontrol.price_both_view?.hasError">
													<span v-if="fcontrol.price_both_view.errors.required">You must enter price both
														view</span>
												</div>
												<label>Price Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_other_view?.hasError && fcontrol.price_other_view.touched }"
													v-model="fdata.price_other_view" @blur="fcontrol.price_other_view.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_other_view')" />
												<div class="invalid-feedback" v-if="fcontrol.price_other_view?.hasError">
													<span v-if="fcontrol.price_other_view.errors.required">You must enter price other
														view</span>
												</div>
												<label>Price Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.price_opg?.hasError && fcontrol.price_opg.touched }"
													v-model="fdata.price_opg" @blur="fcontrol.price_opg.touched = true"
													placeholder=" " @keyup="calculateAgentPrice('price_opg')" />
												<div class="invalid-feedback" v-if="fcontrol.price_opg?.hasError">
													<span v-if="fcontrol.price_opg.errors.required">You must enter price OPG</span>
												</div>
												<label>Price OPG</label>
											</div>
										</div>

										<div class="agent-price-box">
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_single_view?.hasError && fcontrol.agent_price_single_view.touched }"
													v-model="fdata.agent_price_single_view"
													@blur="fcontrol.agent_price_single_view.touched = true" readonly placeholder=" " />

												<label>Commission Single View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_double_view?.hasError && fcontrol.agent_price_double_view.touched }"
													v-model="fdata.agent_price_double_view"
													@blur="fcontrol.agent_price_double_view.touched = true" readonly placeholder=" " />

												<label>Commission Double View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_multi_view?.hasError && fcontrol.agent_price_multi_view.touched }"
													v-model="fdata.agent_price_multi_view"
													@blur="fcontrol.agent_price_multi_view.touched = true" readonly placeholder=" " />

												<label>Commission Multi View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_both_view?.hasError && fcontrol.agent_price_both_view.touched }"
													v-model="fdata.agent_price_both_view"
													@blur="fcontrol.agent_price_both_view.touched = true" readonly placeholder=" " />

												<label>Commission Both View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_other_view?.hasError && fcontrol.agent_price_other_view.touched }"
													v-model="fdata.agent_price_other_view"
													@blur="fcontrol.agent_price_other_view.touched = true" readonly placeholder=" " />

												<label>Commission Other View</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.agent_price_opg?.hasError && fcontrol.agent_price_opg.touched }"
													v-model="fdata.agent_price_opg"
													@blur="fcontrol.agent_price_opg.touched = true" readonly placeholder=" " />

												<label>Commission OPG</label>
											</div>

											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.cardiology_agent_price?.hasError && fcontrol.cardiology_agent_price.touched }"
													v-model="fdata.cardiology_agent_price"
													@blur="fcontrol.cardiology_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission ECG</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.tmt_agent_price?.hasError && fcontrol.tmt_agent_price.touched }"
													v-model="fdata.tmt_agent_price"
													@blur="fcontrol.tmt_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission TMT</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.pulmonology_agent_price?.hasError && fcontrol.pulmonology_agent_price.touched }"
													v-model="fdata.pulmonology_agent_price"
													@blur="fcontrol.pulmonology_agent_price.touched = true" readonly placeholder=" " />

												<label>Agent Commission PFT</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg2_agent_price?.hasError && fcontrol.eeg2_agent_price.touched }"
													v-model="fdata.eeg2_agent_price"
													@blur="fcontrol.eeg2_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG 2</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg3_agent_price?.hasError && fcontrol.eeg3_agent_price.touched }"
													v-model="fdata.eeg3_agent_price"
													@blur="fcontrol.eeg3_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG 3</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg4_agent_price?.hasError && fcontrol.eeg4_agent_price.touched }"
													v-model="fdata.eeg4_agent_price"
													@blur="fcontrol.eeg4_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG 4</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg5_agent_price?.hasError && fcontrol.eeg5_agent_price.touched }"
													v-model="fdata.eeg5_agent_price"
													@blur="fcontrol.eeg5_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG 5</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg6_agent_price?.hasError && fcontrol.eeg6_agent_price.touched }"
													v-model="fdata.eeg6_agent_price"
													@blur="fcontrol.eeg6_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG 6</label>
											</div>
											<div class="form-group ui-float-label">
												<input type="number" class="form-control"
													:class="{ 'is-invalid': fcontrol.eeg7_agent_price?.hasError && fcontrol.eeg7_agent_price.touched }"
													v-model="fdata.eeg7_agent_price"
													@blur="fcontrol.eeg7_agent_price.touched = true" readonly placeholder=" " />

												<label>Commission EEG 7</label>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { Modal } from 'bootstrap'
export default {

	data() {
		return {
			dateRange: "",
			totalReports: 0,
			rows: [],
			centers: [],
			reports: {},
			testPrice: 0,
			doctorPrice: 0,
			agentPrice: 0,
			isSuper: false,

			addModal: null,
			defaultRates: {},

			fdata: {
				code: '',
				name: '',
				email: '',
				password: '',
				contact_person: '',
				phone: '',
				state: '',
				address: '',
				created_at: '',
				is_active: 'y',
				minimum_balance: 0,
				show_disclaimer: 'y',
				price_single_view: '',
				price_double_view: '',
				price_multi_view: '',
				price_both_view: '',
				price_other_view: '',
				price_opg: '',
				doctor_ids: '',
				cardiology_price: '',
				tmt_price: '',
				cardiologist_ids: '',
				pulmonology_price: '',
				pulmonologist_ids: '',
				eeg2_price: '',
				eeg3_price: '',
				eeg4_price: '',
				eeg5_price: '',
				eeg6_price: '',
				eeg7_price: '',
				// ncv_price: '',
				neurologist_ids: '',
				agent_id: '',
				agent_price_single_view: '',
				agent_price_double_view: '',
				agent_price_multi_view: '',
				agent_price_both_view: '',
				agent_price_other_view: '',
				agent_price_opg: '',
				cardiology_agent_price: '',
				tmt_agent_price: '',
				pulmonology_agent_price: '',
				eeg2_agent_price: '',
				eeg3_agent_price: '',
				eeg4_agent_price: '',
				eeg5_agent_price: '',
				eeg6_agent_price: '',
				eeg7_agent_price: '',
				// ncv_agent_price: '',
			},
			fvalidator: {
				code: 'required|max:10',
				name: 'required|max:50',
				email: 'required|max:50',
				password: 'required|max:30',
				contact_person: 'required|max:50',
				phone: 'required|max:20',
				state: 'required|max:255',
				address: 'required|max:255',
				created_at: '',
				is_active: 'required|in:y,n',
				minimum_balance: '',
				show_disclaimer: 'required|in:y,n',
				price_single_view: 'required',
				price_double_view: 'required',
				price_multi_view: 'required',
				price_both_view: 'required',
				price_other_view: 'required',
				price_opg: 'required',
				doctor_ids: '',
				cardiology_price: 'required',
				tmt_price: 'required',
				cardiologist_ids: '',
				pulmonology_price: 'required',
				pulmonologist_ids: '',
				eeg2_price: 'required',
				eeg3_price: 'required',
				eeg4_price: 'required',
				eeg5_price: 'required',
				eeg6_price: 'required',
				eeg7_price: 'required',
				// ncv_price: 'required',
				neurologist_ids: '',
				agent_id: '',
				agent_price_single_view: '',
				agent_price_double_view: '',
				agent_price_multi_view: '',
				agent_price_both_view: '',
				agent_price_other_view: '',
				agent_price_opg: '',
				cardiology_agent_price: '',
				tmt_agent_price: '',
				pulmonology_agent_price: '',
				eeg2_agent_price: '',
				eeg3_agent_price: '',
				eeg4_agent_price: '',
				eeg5_agent_price: '',
				eeg6_agent_price: '',
				eeg7_agent_price: '',
				// ncv_agent_price: '',
			}
		}
	},
	async mounted() {
		this.addModal = new Modal(this.$refs.addModal);
		this.loadLazyData();


		this.defaultRates = await this.get("agent/load-defualt-rates");

		let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_eeg2', 'min_eeg3', 'min_eeg4', 'min_eeg5', 'min_eeg6', 'min_eeg7' ];
		let minCols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'eeg2_price', 'eeg3_price', 'eeg4_price', 'eeg5_price', 'eeg6_price', 'eeg7_price' ];
		

		for(let i=0; i < mins.length; i++){
			let m = Number(this.defaultRates[mins[i]]);
			if(m){
				this.fcontrol[minCols[i]].validators.push({
					name: 'gte',
					params: [m]
				})
			}
		}
	},
	methods: {
		makeCenterCode(){
			if(this.editId) return;
			let code = '';

			let name = this.fdata.name ? this.fdata.name.toUpperCase().split(" ") : [];
			for(let p of name){
				if(p){
					code = code + p[0]
				}
			}

			let d = new Date();
			code = code + String(d.getFullYear()).substr(-2) + '' + (d.getMonth()+1) + '' + d.getDate();

			this.fdata.code = code;
		},
		async loadLazyData() {
			let res = await this.get("agent/load-centers");
			this.rows = res.rows;
		},
		calculateAgentPrice(col){
			let row = this.defaultRates;

			let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_eeg2', 'min_eeg3', 'min_eeg4', 'min_eeg5', 'min_eeg6', 'min_eeg7' ];
			let cols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'eeg2_price', 'eeg3_price', 'eeg4_price', 'eeg5_price', 'eeg6_price', 'eeg7_price' ];
			let agents = [ 'agent_price_single_view', 'agent_price_double_view', 'agent_price_multi_view', 'agent_price_both_view', 'agent_price_other_view', 'agent_price_opg', 'cardiology_agent_price', 'tmt_agent_price', 'pulmonology_agent_price', 'eeg2_agent_price', 'eeg3_agent_price', 'eeg4_agent_price', 'eeg5_agent_price', 'eeg6_agent_price', 'eeg7_agent_price' ];

			let i = cols.indexOf(col);
			
			if(row[mins[i]] && this.fdata[cols[i]]){
				let c = this.fdata[cols[i]] - row[mins[i]];
				if(c < 0) c = 0;
				this.fdata[agents[i]] = c;
			}
		},

		async applyDefaultPrice() {
			let row = this.defaultRates;
			this.fpopulate(row);

			let mins = [ 'min_single_view', 'min_double_view', 'min_multi_view', 'min_both_view', 'min_other_view', 'min_opg', 'min_cardiology', 'min_tmt', 'min_pulmonology', 'min_eeg2', 'min_eeg3', 'min_eeg4', 'min_eeg5', 'min_eeg6', 'min_eeg7' ];
			let cols = [ 'price_single_view', 'price_double_view', 'price_multi_view', 'price_both_view', 'price_other_view', 'price_opg', 'cardiology_price', 'tmt_price', 'pulmonology_price', 'eeg2_price', 'eeg3_price', 'eeg4_price', 'eeg5_price', 'eeg6_price', 'eeg7_price' ];
			let agents = [ 'agent_price_single_view', 'agent_price_double_view', 'agent_price_multi_view', 'agent_price_both_view', 'agent_price_other_view', 'agent_price_opg', 'cardiology_agent_price', 'tmt_agent_price', 'pulmonology_agent_price', 'eeg2_agent_price', 'eeg3_agent_price', 'eeg4_agent_price', 'eeg5_agent_price', 'eeg6_agent_price', 'eeg7_agent_price' ];

			for(let i=0; i<mins.length; i++){
				if(row[mins[i]] && row[cols[i]]){
					let c = row[cols[i]] - row[mins[i]];
					if(c < 0) c = 0;
					this.fdata[agents[i]] = c;
				}
			}
		},
		doubleRate(type){
			if(type == 'single_view'){
				this.fdata.price_both_view = Number(this.fdata.price_single_view) * 2;
			}else if(type == 'multi_view'){
				this.fdata.price_other_view = Number(this.fdata.price_multi_view) * 2;
			}
		},
		putCenter() {
			if(this.fHasError()) console.log(this.fvalidator);
			if (this.fHasError() || this.saving) return;
			this.saving = true;

			let data = JSON.parse(JSON.stringify(this.fdata));
			console.log(data);
			
			this.post("agent/put-center", data).then(res => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
				} else if (res.codeExists) {
					alert("Center code already exists.")
				}
			});
		},
		showAddEdit() {
			this.freset();
			setTimeout(()=>{
				this.applyDefaultPrice();
			}, 500)
			this.addModal.show();
		},
	},
}
</script>
<style scoped>
.center-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #a4c4e3;
	border-radius: 10px;
}

.doctor-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d9dfe6;
	border-radius: 10px;
}

.agent-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eec9e9;
	border-radius: 10px;
}

.ecg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #eeebc9;
	border-radius: 10px;
}
.pft-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #d1ffba;
	border-radius: 10px;
}
.eeg-price-box {
	padding: 8px 12px;
	margin-bottom: 15px;
	background: #c9eee7;
	border-radius: 10px;
}

</style>